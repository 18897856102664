<template>
  <q-avatar icon="colorize" class="shadow-2 cursor-pointer" :class="`bg-${chosenColour} text-${textStringContrast(chosenColour)}`" :size="size">
    <q-popup-proxy>
      <div class="colourPicker bg-white">
        <div
          v-for="(colour, hex) in colourPalette"
          :key="colour"
          :class="`colourOption shadow-2 bg-${colour}`"
          @click="chooseColour(hex)"
        >
          <q-tooltip>
            {{ hex }}
          </q-tooltip>
        </div>
        <q-btn
          v-if="chosenColour !== 'primary'"
          label="reset"
          class="full-width"
          flat
          @click="resetColour"
        />
      </div>
    </q-popup-proxy>
  </q-avatar>
</template>
<script>
import colours from 'mixins/colours.js'
export default {
  mixins: [colours],
  props: {
    size: {
      type: String,
      default: '128px'
    },
    value: String
  },
  data () {
    return {
      more: false
    }
  },
  computed: {
    chosenColour: {
      get () {
        return this.value || 'primary'
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  mounted () {
    if (!this.value) {
      this.$nextTick(() => {
        this.resetColour()
      })
    }
  },
  methods: {
    chooseColour (hex) {
      this.chosenColour = this.colourPalette[hex]
    },
    resetColour () {
      this.chosenColour = 'primary'
    }
  }
}
</script>
<style lang="stylus" scoped>
.colourPicker
  width 350px
  padding 16px
.colourOption
  width 45px
  height 45px
  display inline-block
  margin 4px
  cursor pointer
.moreColours
  max-height 0px
  opacity 0
  transition max-height .3s
  &.show
    max-height 400px
    opacity 1
</style>
